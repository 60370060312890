import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"

const EventCard = ({ event, animationFullList }) => {
  let myRef = React.createRef()
  const handleClick = () => {
    myRef.current.click()
  }

  const { url, titel, titelbild, beschreibung, createdAt } = event
  return (
    <div
      onClick={handleClick}
      aria-hidden="true"
      role="button"
      data-sal="fade"
      data-sal-delay={animationFullList}
      data-sal-duration="1500"
      data-sal-easing="ease"
      className="eventCard"
    >
      <div className="card zoom ">
        <div className="card-image">
          <Image className="cardImage" fluid={titelbild.fluid} alt=""></Image>
          <time className="eventCard-date has-text-white">{createdAt}</time>
        </div>
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <h4 className="title is-4">{titel}</h4>
            </div>
          </div>
          <div className="content">
            <p className="is-size-6">{beschreibung}</p>
          </div>
        </div>
        <footer className="card-footer">
          <p className="card-footer-item">
            <span>
              <Link
                ref={myRef}
                to={`/veranstaltungen/${url}`}
                className="button is-link is-outlined"
              >
                Mehr erfahren
              </Link>
            </span>
          </p>
        </footer>
      </div>
    </div>
  )
}

export default EventCard
