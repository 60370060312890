import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import EventCard from "../components/Events/EventCard"

const Event = props => {
  const { numberOfPages, currentPage } = props.pageContext
  const { data } = props

  //Disable prev and next
  const previous = currentPage === 1 ? true : false
  const next = currentPage === numberOfPages ? true : false

  let pages = []

  const range = (from, to, step = 1) => {
    let i = from
    const range = []

    while (i < 1) {
      i++
    }
    while (to > numberOfPages) {
      to--
    }

    while (i <= to) {
      range.push(i)
      i += step
    }

    // console.log("range")
    // console.log(range)
    return range
  }

  pages = range(currentPage - 1, currentPage + 1)
  let animationFullList = 0

  const isBrowser = typeof window !== `undefined`

  return (
    <Layout
      title="Veranstaltungen"
      secondTitle="WirtschaftsPlattForm"
      seoTitle="WirtschaftsPlattForm Schwechat | Veranstaltungen"
    >
      <section className="section">
        <div className="container">
          <div className="columns is-multiline">
            {data.events.edges.map(({ node }) => {
              if (isBrowser) {
                if (window.matchMedia("(min-width: 769px)").matches) {
                  if (animationFullList < 600) {
                    animationFullList += 200
                  } else {
                    animationFullList = 200
                  }
                } else {
                  animationFullList = 200
                }
              } else {
                console.log("not browser")
              }

              return (
                <div className="column is-4" key={node.id}>
                  <EventCard
                    event={node}
                    animationFullList={animationFullList}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section className="section">
        <nav
          className="pagination is-centered"
          role="navigation"
          aria-label="pagination"
        >
          <Link
            to={`/veranstaltungen/${currentPage === 2 ? "" : currentPage - 1}`}
            disabled={previous}
            className={`pagination-previous ${previous ? "disabled-link" : ""}`}
          >
            Zurück
          </Link>
          <Link
            to={`/veranstaltungen/${currentPage + 1}`}
            className={`pagination-next  ${next ? "disabled-link" : ""}`}
            disabled={next}
          >
            Weiter
          </Link>
          <ul className="pagination-list">
            {pages.map(page => {
              return (
                <Link
                  className="pagination-link"
                  activeClassName={"is-current"}
                  key={page}
                  to={`/veranstaltungen/${page === 1 ? "" : page}`}
                >
                  {/* {console.log(page + " page")} */}
                  {page}
                </Link>
              )
            })}
          </ul>
        </nav>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query getEvents($skip: Int!, $limit: Int!) {
    events: allContentfulVeranstaltung(
      skip: $skip
      limit: $limit
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          url
          titel
          beschreibung
          id: contentful_id
          createdAt(formatString: "MMM DD, YYYY", locale: "de")
          titelbild {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default Event
